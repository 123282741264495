// .player-container {
.severity {
	padding: 0px 10px;
	border-radius: 10px;
	min-width: 85px;
	max-width: 85px;
	text-align: center;
	font-size: 12px;

	&.success {
		color: #fff;
		border-color: #67af67;
		background-color: #67af67;
	}
	&.error {
		color: #fff;
		border-color: #e45858;
		background-color: #e45858;
	}
	&.warning {
		color: #fff;
		border-color: #1876d2;
		background-color: #1876d2;
	}

	&.deepening {
		color: #000;
		border: 1px solid #1876d2;
		max-width: 110px;
	}
}
// }
