.MuiDrawer-root {
	display: none !important;
}

.side-container {
	height: 100%;
	display: flex;
	flex-flow: column;
	min-width: 225px;
	background: linear-gradient(to top, #fff 0%, #fff 50%, #fcfcfc 100%);
	padding: 10px 0 !important;
	gap: 0px;

	.MuiCollapse-root {
		.MuiListItem-root {
			padding-left: 45px;

			.MuiListItemIcon-root {
				.item-icon {
					width: 25px;
				}
			}
		}
	}

	.MuiListItem-root {
		padding: 5px;
		gap: 10px;
		width: 100%;
		border-bottom: 1px solid #ebf1fd;

		&.selected {
			background-color: #ebf1fd;
		}

		.MuiTypography-root {
			font-size: 14px;
		}

		.MuiListItemIcon-root {
			min-width: 40px;
			justify-content: center;

			.MuiSvgIcon-root {
				color: #2dbb70;
			}

			.item-icon {
				width: 30px;
			}
		}
	}
}

.side-menu-container {
	position: relative;

	.sandwich {
		display: none;
	}
}

.user-info-container {
	background-color: #ebf1fd;
	position: absolute;
	z-index: 1;
	bottom: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;

	.user-icon-container {
		.user-icon {
			width: 40px;
		}
	}

	.user-container {
		margin-left: 8px;

		.user-role {
			font-size: 12px;
			font-style: italic;
		}
	}
}

@media (max-width: 991px) {
	.side-menu-container {
		.side-container {
			border-radius: 0px;
		}
	}
}

@media (max-width: 767px) {
	.MuiDrawer-root {
		display: block !important;

		.side-container {
			display: flex;
		}
	}

	.side-container {
		display: none;
	}

	.side-menu-container {
		position: inherit;

		.sandwich {
			display: block !important;
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			background-color: #f7f7f7;
			z-index: 1;
		}
	}
}
