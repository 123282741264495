.homepage-container {
	font-size: 16px;
	height: 100%;

	.application-sections-boxes {
		display: flex;
		align-items: center;
		gap: 25px;
		flex-wrap: wrap;
	}

	.sections-info-container {
		display: flex;
		flex-flow: column;
		gap: 15px;
		margin-top: 25px;

		.section-info {
			background-color: #fff9b1;
			box-shadow: 2px 2px 4px #bdbfc3;
			text-align: center;
			width: 100%;
			padding: 10px;
		}
	}

	.homepage-block {
		height: calc(var(--doc-height) / 2 - 35px);

		&.closed {
			height: 60px;
		}

		.MuiPaper-root {
			height: 100%;

			.MuiCardContent-root {
				height: 95%;

				.card-content {
					&.open {
						.card-padded-content {
							overflow-y: auto;
							height: 100%;
						}

						.card-open {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.homepage-container {
		.application-sections-boxes {
			gap: 15px;
		}

		.homepage-block {
			height: auto;

			.card-open {
				display: block !important;
			}
		}
	}
}

@media (max-width: 767px) {
	.homepage-container {
		.application-sections-boxes {
			flex-flow: column;

			.section-box {
				width: 100%;
			}
		}

		.homepage-block {
			height: auto;
		}
	}
}
