.table-container {
	display: flex;
	flex-flow: column;
	position: relative;
	overflow: hidden;
	margin-top: 5px;

	&.full-width {
		.elements-container {
			.element-container {
				&:nth-child(odd) {
					background-color: #ebf1fd;
				}
				&.warning {
					background-color: #ffcece;
				}
				.element-field {
					.MuiBox-root {
						.MuiCircularProgress-root {
							height: 25px !important;
							width: 25px !important;
						}

						.MuiTypography-root {
							font-size: 0.8rem !important;
							font-weight: bold !important;
						}
					}

					.cell {
						width: 20px;
						height: 20px;
						border-radius: 50%;

						&.on {
							background-color: #67af67;
						}

						&.off {
							background-color: #e45858;
						}
					}

					.actions-row-container {
						.action {
							.MuiSvgIcon-root {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
			}
		}
	}

	&.fixed-filters {
		.elements-container {
			&.full-height {
				overflow-y: auto;
				height: calc(var(--doc-height) - 165px - 50px);
			}
		}
	}

	&.filtered {
		.elements-container {
			&.full-height {
				overflow-y: auto;
				height: calc(var(--doc-height) - 110px - 50px);
			}
		}
	}

	&.breadcrumbs {
		.elements-container {
			&.full-height {
				overflow-y: auto;
				height: calc(var(--doc-height) - 85px - 50px);
			}
		}

		&.fixed-filters {
			.elements-container {
				&.full-height {
					height: calc(var(--doc-height) - 195px - 50px);
				}
			}
		}

		&.filtered {
			.elements-container {
				&.full-height {
					height: calc(var(--doc-height) - 140px - 50px);
				}
			}
		}
	}

	.title-container {
		margin-bottom: 5px;

		.title-label {
			font-weight: bold;
			font-size: 16px;
		}

		.title-actions-container {
			display: flex;
			align-items: center;
			gap: 10px;

			.MuiSvgIcon-root {
				width: 20px;
				height: 20px;

				&:hover {
					background-color: lightgray;
				}
			}
		}
	}

	.table-header-container {
		display: flex;
		align-items: center;
		width: 100%;
		background-color: #fff;
		border-radius: 10px 10px 0px 0px;
		padding: 5px 10px;
		margin-bottom: 5px;
		height: 30px;
		position: sticky;
		top: 0;
		// z-index: 1;

		.header-field {
			display: flex;
			flex: 3;
			font-weight: bold;
			line-height: 1;
			// text-align: center;

			&.md {
				flex: 2;
			}

			&.sm {
				flex: 1;
			}
		}
	}

	.elements-container {
		&.full-height {
			overflow-y: auto;
			height: calc(var(--doc-height) - 80px - 50px);
		}

		&.middle-height {
			overflow-y: auto;
			max-height: 300px;
		}

		.element-container {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 5px 10px;
			margin: 2px 0px;
			margin-bottom: 0px;
			background-color: #fff;

			&.clickable {
				cursor: pointer;

				&:hover {
					background-color: #ecebeb;
				}
			}

			&:last-child {
				margin-bottom: 0px;
			}

			.element-field {
				display: flex;
				flex: 3;

				.MuiTypography-root {
					font-size: 1rem;
					font-weight: bold;
				}

				.highlighted-value {
					background-color: #ffffb9;
					border-radius: 10px;
					padding: 0px 4px;
				}

				.image {
					height: 30px;
				}

				&.md {
					flex: 2;
				}

				&.sm {
					flex: 1;
					display: flex;
					align-items: center;
				}

				.actions-row-container {
					display: flex;
					align-items: center;
					gap: 5px;

					.action {
						display: flex;
						align-items: center;

						.MuiSvgIcon-root {
							width: 18px;
							height: 18px;

							&:hover {
								background-color: lightgray;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.table-container {
		.title-container {
			.title-actions-container {
				.MuiSvgIcon-root {
					width: 24px;
					height: 24px;
				}
			}
		}
		.charts-container {
			flex-wrap: wrap;
		}
	}
}
